/* eslint-disable jsdoc/no-undefined-types */
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { useEffect, useState } from "react";
import { useErrorBoundary } from "react-error-boundary";
import { frontendAuth, getGravatarImage, getTenantDetail, TopNavConfigResponse } from "../../common/getConfig";
import AppSwitcherList from "./app-switcher-list";
import UserSettings from "./user-settings";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import "./common.css";
import { Avatar, IconButton } from "@mui/material";

/**
 *
 * @returns {JSX.Element} JSX
 */

const TopNavigation = ({ selectTheme }) => {
  const { showBoundary } = useErrorBoundary();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [anchorEl2, setAnchorEl2] = useState<null | HTMLElement>(null);
  const [openAlert, setOpenAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setMenuList(menuConfig);
    setAnchorEl(event.currentTarget);
  };

  const getOrgLogo = (baseURI, orgId) => {
    return `${baseURI}assets/organizations/${orgId}.png`;
  };
  const handleProfileClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl2(event.currentTarget);
  };
  const [menuConfig, setMenuConfig] = useState([]);
  const [tenantDetail, setTenantDetail] = useState<TopNavConfigResponse>();
  const [isValidImage, setIsValidImage] = useState(true);
  const [defaultOrgURI, setDefaultOrgURI] = useState("");
  const [menuList, setMenuList] = useState([]);
  const [orgLogoImageURI, setOrgLogoImageURI] = useState("");
  const [user, setUser] = useState("");
  const [userJobTitle, setUserJobTitle] = useState("");
  const [gravatarProfile, setgravatarProfile] = useState(null);
  const [baseURI, setBaseURI] = useState(null);
  const [userSettings, setUserSettings] = useState({});

  const fetchData = async () => {
    try {
      const frontendAuthentication = await frontendAuth();
      const user = await frontendAuthentication.getUser();
      if (user.email === undefined) {
        setAlertMessage("User email is empty, please contact your administrator to update your email.");
        setOpenAlert(true);
      }
      if (user.name === undefined) {
        setAlertMessage("User name is empty, please contact your administrator to update your name.");
        setOpenAlert(true);
      }
      setUser(user.name || "");
      setUserJobTitle(user.job_title);

      const accessToken = await frontendAuthentication.getToken();
      const tenant: TopNavConfigResponse = await getTenantDetail(accessToken);
      setUserSettings({
        email: user.email || "",
        name: user.name || "",
        jobTitle: user.job_title,
        icon: getGravatarImage(user.email || "", tenant.assetPath),
      });
      setTenantDetail(tenant);
      setBaseURI(tenant.assetPath);

      const orgLogoImageURI = getOrgLogo(tenant.assetPath, tenant.orgId);
      setgravatarProfile(getGravatarImage(user.email || "", tenant.assetPath));
      setMenuConfig(tenant.menuConfig);
      setMenuList(tenant.menuConfig);
      setOrgLogoImageURI(orgLogoImageURI);
      setDefaultOrgURI(`${tenant.assetPath}assets/oxygen.svg`);
    } catch (err) {
      console.error(err.message);
      showBoundary(err);
    }
  };

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlert(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    // Check if the image is valid (exist) or not by loading it in an image element
    const img = new Image();
    img.onload = () => {
      setIsValidImage(true);
    };
    img.onerror = () => {
      setIsValidImage(false);
    };
    img.src = orgLogoImageURI;
  }, [orgLogoImageURI]);
  return (
    <>
      <Snackbar
        open={openAlert}
        autoHideDuration={3000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert onClose={handleClose} severity="warning" variant="filled" sx={{ width: "100%" }}>
          {alertMessage}
        </Alert>
      </Snackbar>
      <AppBar className="top-navigation">
        <Box className="top-navigation--links">
          <Box className="top-navigation--app-switcher">
            <Box
              sx={{ cursor: "pointer" }}
              display="flex"
              alignItems="center"
              onClick={handleMenuClick}
              data-testid="menuIconTestId"
            >
              <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M5 5.97852C5 5.42623 5.44772 4.97852 6 4.97852H14C14.5523 4.97852 15 5.42623 15 5.97852V13.9785C15 14.5308 14.5523 14.9785 14 14.9785H6C5.44772 14.9785 5 14.5308 5 13.9785V5.97852ZM7 6.97852V12.9785H13V6.97852H7ZM17 5.97852C17 5.42623 17.4477 4.97852 18 4.97852H26C26.5523 4.97852 27 5.42623 27 5.97852V13.9785C27 14.5308 26.5523 14.9785 26 14.9785H18C17.4477 14.9785 17 14.5308 17 13.9785V5.97852ZM19 6.97852V12.9785H25V6.97852H19ZM5 17.9785C5 17.4262 5.44772 16.9785 6 16.9785H14C14.5523 16.9785 15 17.4262 15 17.9785V25.9785C15 26.5308 14.5523 26.9785 14 26.9785H6C5.44772 26.9785 5 26.5308 5 25.9785V17.9785ZM7 18.9785V24.9785H13V18.9785H7ZM21.9973 25.0216C23.6542 25.0216 24.9973 23.6785 24.9973 22.0216C24.9973 20.3648 23.6542 19.0216 21.9973 19.0216C20.3405 19.0216 18.9973 20.3648 18.9973 22.0216C18.9973 23.6785 20.3405 25.0216 21.9973 25.0216ZM26.9973 22.0216C26.9973 24.783 24.7588 27.0216 21.9973 27.0216C19.2359 27.0216 16.9973 24.783 16.9973 22.0216C16.9973 19.2602 19.2359 17.0216 21.9973 17.0216C24.7588 17.0216 26.9973 19.2602 26.9973 22.0216Z"
                  fill="#F6F8FA"
                />
              </svg>
            </Box>

            <Box sx={{ flexGrow: 1, columnGap: 2 }} alignItems="center" display="flex">
              <div
                className="organisation-logo"
                data-testid="tenantLogoId"
                style={{ backgroundImage: `url(${!isValidImage ? defaultOrgURI : orgLogoImageURI})` }}
              ></div>

              <Typography fontSize="20px" variant="body1" data-testid="tenantNameId">
                {tenantDetail?.tenantName}
              </Typography>
            </Box>
          </Box>
          <Box className="top-navigation--toolbar">
            <Box sx={{ cursor: "pointer" }} display="flex" alignItems="center" gap="8px" onClick={handleProfileClick}>
              <Box sx={{ textAlign: "right" }} display="flex" flexDirection="column" gap="4px">
                <Typography data-testid="authUserName" fontSize={14} fontWeight={700}>
                  {user}
                </Typography>
                <Typography
                  fontSize={12}
                  fontWeight={500}
                  lineHeight={1}
                  variant="body2"
                  data-testid="authUserJobTitle"
                >
                  {userJobTitle}
                </Typography>
              </Box>
              <Box
                role="button"
                aria-label="account-icon"
                display="flex"
                justifyContent="center"
                alignItems="center"
                height={48}
                width={48}
                lineHeight={1}
              >
                <IconButton>
                  <Avatar alt="" src={gravatarProfile} />
                </IconButton>
              </Box>
            </Box>
          </Box>
        </Box>
      </AppBar>

      <AppSwitcherList baseURI={baseURI} menuList={menuList} anchorEl={anchorEl} setAnchorEl={setAnchorEl} />
      <UserSettings
        userSettings={userSettings}
        anchorEl2={anchorEl2}
        setAnchorEl2={setAnchorEl2}
        selectTheme={selectTheme}
      />
    </>
  );
};
export default TopNavigation;
