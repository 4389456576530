import React from "react";

type LightModeIconProps = {
  color: string;
};

const LightModeIcon: React.FC<LightModeIconProps> = ({ color }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12 7.125C9.30761 7.125 7.125 9.30761 7.125 12C7.125 14.6924 9.30761 16.875 12 16.875C14.6924 16.875 16.875 14.6924 16.875 12C16.875 9.30761 14.6924 7.125 12 7.125ZM5.625 12C5.625 8.47918 8.47918 5.625 12 5.625C15.5208 5.625 18.375 8.47918 18.375 12C18.375 15.5208 15.5208 18.375 12 18.375C8.47918 18.375 5.625 15.5208 5.625 12Z"
        fill={color}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12 0.75C12.4142 0.75 12.75 1.08579 12.75 1.5V3.375C12.75 3.78921 12.4142 4.125 12 4.125C11.5858 4.125 11.25 3.78921 11.25 3.375V1.5C11.25 1.08579 11.5858 0.75 12 0.75Z"
        fill={color}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.04474 4.04465C4.33764 3.75176 4.81251 3.75176 5.1054 4.04465L6.42728 5.36653C6.72017 5.65942 6.72017 6.13429 6.42728 6.42719C6.13439 6.72008 5.65951 6.72008 5.36662 6.42719L4.04474 5.10531C3.75185 4.81242 3.75185 4.33754 4.04474 4.04465Z"
        fill={color}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0.75 12C0.75 11.5858 1.08579 11.25 1.5 11.25H3.375C3.78921 11.25 4.125 11.5858 4.125 12C4.125 12.4142 3.78921 12.75 3.375 12.75H1.5C1.08579 12.75 0.75 12.4142 0.75 12Z"
        fill={color}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.42728 17.5728C6.72017 17.8657 6.72017 18.3406 6.42728 18.6335L5.1054 19.9553C4.81251 20.2482 4.33764 20.2482 4.04474 19.9553C3.75185 19.6624 3.75185 19.1876 4.04474 18.8947L5.36662 17.5728C5.65951 17.2799 6.13439 17.2799 6.42728 17.5728Z"
        fill={color}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12 19.875C12.4142 19.875 12.75 20.2108 12.75 20.625V22.5C12.75 22.9142 12.4142 23.25 12 23.25C11.5858 23.25 11.25 22.9142 11.25 22.5V20.625C11.25 20.2108 11.5858 19.875 12 19.875Z"
        fill={color}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.5728 17.5728C17.8657 17.2799 18.3405 17.2799 18.6334 17.5728L19.9553 18.8947C20.2482 19.1876 20.2482 19.6624 19.9553 19.9553C19.6624 20.2482 19.1875 20.2482 18.8946 19.9553L17.5728 18.6335C17.2799 18.3406 17.2799 17.8657 17.5728 17.5728Z"
        fill={color}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M19.875 12C19.875 11.5858 20.2108 11.25 20.625 11.25H22.5C22.9142 11.25 23.25 11.5858 23.25 12C23.25 12.4142 22.9142 12.75 22.5 12.75H20.625C20.2108 12.75 19.875 12.4142 19.875 12Z"
        fill={color}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M19.9553 4.04465C20.2482 4.33754 20.2482 4.81242 19.9553 5.10531L18.6334 6.42719C18.3405 6.72008 17.8657 6.72008 17.5728 6.42719C17.2799 6.13429 17.2799 5.65942 17.5728 5.36653L18.8946 4.04465C19.1875 3.75176 19.6624 3.75176 19.9553 4.04465Z"
        fill={color}
      />
    </svg>
  );
};
export default LightModeIcon;
