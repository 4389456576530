import { useState } from "react";
import ThemeSettings from "./theme-settings";
import { Avatar, Divider, IconButton, Typography } from "@mui/material";
import Menu from "@mui/material/Menu";
import Box from "@mui/material/Box";
import { frontendAuth } from "../../common/getConfig";
import LogoutIcon from "./theme/user-settings/logout";
import { Link, BrowserRouter as Router } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import EmailIcon from "../components/theme/user-settings/email";
import "./common.css";

const UserSettings = ({ selectTheme, ...props }) => {
  const open = Boolean(props.anchorEl2);
  const userSettings = props.userSettings;
  const [anchorEl3, setAnchorEl3] = useState(null);
  const theme = useTheme();
  const handleLogOutClick = async () => {
    const frontendAuthentication = await frontendAuth();
    await frontendAuthentication.logout();
    props.setAnchorEl2(null);
  };
  const handleClose = () => {
    props.setAnchorEl2(null);
  };

  return (
    <>
      <Menu
        className="top-navigation-dropdown"
        anchorEl={props.anchorEl2}
        open={open}
        MenuListProps={{
          "aria-labelledby": "lock-button",
          role: "listbox",
        }}
        onClose={handleClose}
      >
        <Box
          className={`top-navigation-user-settings-container top-navigation-user-settings-container-${theme.palette.mode}`}
          style={{
            backgroundColor: theme.palette.mode === "dark" ? "#273B49" : "#FFFFFF",
          }}
        >
          <Router>
            <Box display={"flex"} flexDirection={"column"} rowGap={"8px"}>
              <Box display={"flex"} columnGap={"8px"} alignItems={"flex-start"}>
                <Box
                  role="button"
                  aria-label="account-icon"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  height={48}
                  width={48}
                  lineHeight={1}
                >
                  <IconButton>
                    <Avatar sx={{ width: 56, height: 56 }} alt="" src={userSettings.icon} />
                  </IconButton>
                </Box>
                <Box
                  className={`top-navigation-user-settings-container top-navigation-user-settings-container-${theme.palette.mode}`}
                  display={"flex"}
                  flexDirection={"column"}
                  gap={"8px"}
                  sx={{ padding: 0 }}
                >
                  <Typography fontWeight={700} lineHeight={1} variant="body2">
                    {userSettings.name}
                  </Typography>
                  <Box display={"flex"} alignItems={"center"} gap={"4px"}>
                    <EmailIcon color={theme.palette.mode === "dark" ? "#BDC3C7" : "#4A5B66"} />
                    <Typography
                      fontSize={12}
                      fontWeight={500}
                      variant="body2"
                      style={{ color: theme.palette.mode === "dark" ? "#F6F8FA" : "#0D2433" }}
                    >
                      {userSettings.email}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>

            <Divider sx={{ backgroundColor: "#A8B0B6", margin: "16px 0" }} />
            <Link
              style={{ justifyContent: "space-between" }}
              to=""
              className="top-navigation-user-settings-container--link"
              onClick={() => handleLogOutClick()}
            >
              <Typography variant="body1" style={{ color: theme.palette.mode === "dark" ? "#F6F8FA" : "#0D2433" }}>
                Log Out
              </Typography>
              <LogoutIcon color={theme.palette.mode === "dark" ? "#F6F8FA" : "#0D2433"} />
            </Link>
          </Router>
        </Box>
      </Menu>
      <ThemeSettings anchorEl3={anchorEl3} setAnchorEl3={setAnchorEl3} selectTheme={selectTheme} />
    </>
  );
};
export default UserSettings;
