import React from "react";

type LogoutIconProps = {
  color: string;
};

const LogoutIcon: React.FC<LogoutIconProps> = ({ color }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.4997 20.25C10.4997 20.4489 10.4207 20.6397 10.28 20.7803C10.1394 20.921 9.94862 21 9.74971 21H4.49971C4.10189 21 3.72035 20.842 3.43905 20.5607C3.15775 20.2794 2.99971 19.8978 2.99971 19.5V4.5C2.99971 4.10218 3.15775 3.72064 3.43905 3.43934C3.72035 3.15804 4.10189 3 4.49971 3H9.74971C9.94862 3 10.1394 3.07902 10.28 3.21967C10.4207 3.36032 10.4997 3.55109 10.4997 3.75C10.4997 3.94891 10.4207 4.13968 10.28 4.28033C10.1394 4.42098 9.94862 4.5 9.74971 4.5H4.49971V19.5H9.74971C9.94862 19.5 10.1394 19.579 10.28 19.7197C10.4207 19.8603 10.4997 20.0511 10.4997 20.25ZM20.7803 11.4694L17.0303 7.71937C16.8896 7.57864 16.6987 7.49958 16.4997 7.49958C16.3007 7.49958 16.1098 7.57864 15.9691 7.71937C15.8284 7.86011 15.7493 8.05098 15.7493 8.25C15.7493 8.44902 15.8284 8.63989 15.9691 8.78063L18.4394 11.25H9.74971C9.5508 11.25 9.36003 11.329 9.21938 11.4697C9.07873 11.6103 8.99971 11.8011 8.99971 12C8.99971 12.1989 9.07873 12.3897 9.21938 12.5303C9.36003 12.671 9.5508 12.75 9.74971 12.75H18.4394L15.9691 15.2194C15.8284 15.3601 15.7493 15.551 15.7493 15.75C15.7493 15.949 15.8284 16.1399 15.9691 16.2806C16.1098 16.4214 16.3007 16.5004 16.4997 16.5004C16.6987 16.5004 16.8896 16.4214 17.0303 16.2806L20.7803 12.5306C20.8501 12.461 20.9054 12.3783 20.9431 12.2872C20.9809 12.1962 21.0003 12.0986 21.0003 12C21.0003 11.9014 20.9809 11.8038 20.9431 11.7128C20.9054 11.6217 20.8501 11.539 20.7803 11.4694Z"
        fill={color}
      />
    </svg>
  );
};
export default LogoutIcon;
