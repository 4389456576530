import React from "react";

type DarkModeIconProps = {
  color: string;
};

const DarkModeIcon: React.FC<DarkModeIconProps> = ({ color }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M18.8524 5.63232C19.2667 5.63232 19.6024 5.96811 19.6024 6.38232V10.6011C19.6024 11.0153 19.2667 11.3511 18.8524 11.3511C18.4382 11.3511 18.1024 11.0153 18.1024 10.6011V6.38232C18.1024 5.96811 18.4382 5.63232 18.8524 5.63232Z"
        fill={color}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.9931 8.4917C15.9931 8.07749 16.3289 7.7417 16.7431 7.7417H20.9618C21.376 7.7417 21.7118 8.07749 21.7118 8.4917C21.7118 8.90591 21.376 9.2417 20.9618 9.2417H16.7431C16.3289 9.2417 15.9931 8.90591 15.9931 8.4917Z"
        fill={color}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.6337 2.1167C15.0479 2.1167 15.3837 2.45249 15.3837 2.8667V5.6792C15.3837 6.09341 15.0479 6.4292 14.6337 6.4292C14.2195 6.4292 13.8837 6.09341 13.8837 5.6792V2.8667C13.8837 2.45249 14.2195 2.1167 14.6337 2.1167Z"
        fill={color}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.4774 4.27295C12.4774 3.85874 12.8132 3.52295 13.2274 3.52295H16.0399C16.4542 3.52295 16.7899 3.85874 16.7899 4.27295C16.7899 4.68716 16.4542 5.02295 16.0399 5.02295H13.2274C12.8132 5.02295 12.4774 4.68716 12.4774 4.27295Z"
        fill={color}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.4868 4.72751C9.6781 4.91918 9.75122 5.19908 9.67811 5.45983C9.32694 6.71211 9.31544 8.0353 9.64476 9.29351C9.97409 10.5517 10.6324 11.6996 11.552 12.6192C12.4717 13.5389 13.6196 14.1972 14.8778 14.5265C16.136 14.8559 17.4592 14.8443 18.7115 14.4932C18.9722 14.4201 19.2521 14.4932 19.4438 14.6845C19.6355 14.8758 19.7091 15.1556 19.6365 15.4164C19.2225 16.9037 18.4262 18.2565 17.3266 19.3402C16.2271 20.4239 14.8629 21.2006 13.3698 21.593C11.8767 21.9853 10.3068 21.9796 8.81662 21.5765C7.3264 21.1733 5.96782 20.3867 4.87619 19.2951C3.78457 18.2035 2.998 16.8449 2.59484 15.3547C2.19167 13.8645 2.18598 12.2946 2.57833 10.8015C2.97069 9.30841 3.74738 7.94416 4.83107 6.84465C5.91475 5.74514 7.2676 4.94875 8.75486 4.53479C9.01574 4.46218 9.2955 4.53584 9.4868 4.72751ZM7.96411 6.42671C7.19862 6.79209 6.49933 7.2889 5.89939 7.89759C4.99967 8.81045 4.35483 9.9431 4.02908 11.1827C3.70334 12.4224 3.70806 13.7257 4.04278 14.9629C4.37751 16.2002 5.03054 17.3281 5.93685 18.2344C6.84316 19.1407 7.97111 19.7938 9.20835 20.1285C10.4456 20.4632 11.7489 20.468 12.9886 20.1422C14.2282 19.8165 15.3608 19.1716 16.2737 18.2719C16.8824 17.672 17.3792 16.9727 17.7446 16.2072C16.6615 16.3321 15.5605 16.2558 14.498 15.9776C12.9822 15.5809 11.5993 14.7878 10.4914 13.6799C9.38346 12.572 8.5904 11.1891 8.19365 9.67333C7.91554 8.6108 7.83919 7.50979 7.96411 6.42671Z"
        fill={color}
      />
    </svg>
  );
};
export default DarkModeIcon;
