import React from "react";

type EmailIconProps = {
  color: string;
};

const EmailIcon: React.FC<EmailIconProps> = ({ color }) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1.44708 3.18594C1.45552 3.17575 1.46436 3.1659 1.47358 3.1564C1.52205 3.10642 1.57868 3.0683 1.63959 3.04221C1.70047 3.01606 1.76755 3.00112 1.83809 3.00006L1.84804 3H14.1519L14.1619 3.00006C14.2324 3.00112 14.2995 3.01606 14.3604 3.04221C14.4212 3.06825 14.4777 3.10628 14.5261 3.15613C14.6132 3.24575 14.6666 3.36679 14.6666 3.5V3.50154V12C14.6666 12.2652 14.5586 12.5196 14.3662 12.7071C14.1739 12.8946 13.913 13 13.641 13H2.35895C2.08694 13 1.82606 12.8946 1.63372 12.7071C1.44137 12.5196 1.33331 12.2652 1.33331 12V3.50159V3.5C1.33331 3.48945 1.33365 3.47899 1.33431 3.4686C1.34109 3.36185 1.38223 3.26417 1.44708 3.18594ZM2.35895 4.63662V11.3626L6.12082 7.99879L2.35895 4.63662ZM12.8364 12L9.12023 8.67706L8.3465 9.36858C8.15044 9.54381 7.84952 9.54381 7.65345 9.36858L6.87972 8.67706L3.16352 12H12.8364ZM9.87914 7.99879L13.641 4.63662V11.3626L9.87914 7.99879ZM3.16449 4L7.99998 8.32172L12.8355 4H3.16449Z"
        fill={color}
      />
    </svg>
  );
};
export default EmailIcon;
