import React from "react";

type RadioButtonSelectedProps = {
  color: string;
};

const RadioButtonSelected: React.FC<RadioButtonSelectedProps> = ({ color }) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="8" cy="8" r="7.5" stroke={color} />
      <circle cx="8" cy="8" r="4" fill={color} />
    </svg>
  );
};
export default RadioButtonSelected;
