import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Link, BrowserRouter as Router } from "react-router-dom";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import { Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import "./common.css";

const AppSwitcherList = (props) => {
  const theme = useTheme();
  const menuList = props.menuList;
  const open = Boolean(props.anchorEl);
  const handleMenuSelection = () => {
    props.setAnchorEl(null);
  };
  const handleClose = () => {
    props.setAnchorEl(null);
  };
  return (
    <Menu
      id="lock-menu"
      className="top-navigation-dropdown"
      anchorEl={props.anchorEl}
      open={open}
      MenuListProps={{
        "aria-labelledby": "lock-button",
        role: "listbox",
      }}
      onClose={handleClose}
    >
      <MenuItem key="menu0">
        <b>Switch to</b>
      </MenuItem>

      <Box
        className="top-navigation-switcher-container"
        style={{ backgroundColor: theme.palette.mode === "dark" ? "#001828" : "#FFFFFF" }}
      >
        {menuList &&
          menuList[0] &&
          menuList.map((menu, index) => (
            <Router>
              <Link
                key={index}
                to={menu.pathRoute}
                className={`top-navigation-switcher-container--link top-navigation-switcher-container--link-${theme.palette.mode}`}
                onClick={() => handleMenuSelection()}
              >
                <img src={menu.iconUrl} alt={menu.name} />
                <Typography variant="body1">{menu.name}</Typography>
              </Link>
            </Router>
          ))}

        <Divider variant="middle" sx={{ backgroundColor: "#A8B0B6" }} />

        <Box className="top-navigation-gentrack-logo--poweredby">
          <div
            aria-label="Powered by Gentrack"
            style={{ backgroundImage: `url(${props.baseURI + `assets/gentrack-logo-${theme.palette.mode}.svg`})` }}
          ></div>
        </Box>
      </Box>
    </Menu>
  );
};
export default AppSwitcherList;
