import { Typography } from "@mui/material";
import Menu from "@mui/material/Menu";
import Box from "@mui/material/Box";
import LightModeIcon from "./theme/light-mode";
import DarkModeIcon from "./theme/dark-mode";
import RadioButtonDefault from "./theme/radio-button-default";
import RadioButtonSelected from "./theme/radio-button-selected";
import { useTheme } from "@mui/material/styles";
import "./common.css";

const ThemeSettings = ({ selectTheme, ...props }) => {
  const open = Boolean(props.anchorEl3);
  const theme = useTheme();

  const handleClose = () => {
    console.log(props);
    props.setAnchorEl3(null);
  };

  return (
    <Menu
      className={`top-navigation-dropdown ${
        theme.palette.mode === "light"
          ? "top-navigation-user-settings-container-light"
          : "top-navigation-user-settings-container-dark"
      }`}
      anchorEl={props.anchorEl3}
      open={open}
      MenuListProps={{
        "aria-labelledby": "lock-button",
        role: "listbox",
      }}
      onClose={handleClose}
      transformOrigin={{ vertical: 50, horizontal: 228 }}
    >
      <Box
        className="top-navigation-user-settings-subcontainer"
        style={{ backgroundColor: theme.palette.mode === "dark" ? "#273B49" : "#FFFFFF" }}
      >
        <Box
          className={`top-navigation-user-settings-container--link ${
            theme.palette.mode === "light" ? "top-navigation-menu--selected-light" : ""
          }`}
          style={{ background: theme.palette.mode === "light" ? "#F5F1FA" : "" }}
          onClick={() => selectTheme("light")}
        >
          {theme.palette.mode === "light" ? (
            <RadioButtonSelected color={"#440099"} />
          ) : (
            <RadioButtonDefault color={"#F6F8FA"} />
          )}
          <Box flex={1} display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
            <Typography
              variant="body1"
              style={{
                color: theme.palette.mode === "dark" ? "#F6F8FA" : "#440099",
                fontWeight: theme.palette.mode === "light" ? "500" : "",
              }}
            >
              Light
            </Typography>
            <LightModeIcon color={theme.palette.mode === "dark" ? "#F6F8FA" : "#440099"} />
          </Box>
        </Box>

        <Box
          className={`top-navigation-user-settings-container--link ${
            theme.palette.mode === "dark" ? "top-navigation-menu--selected-dark" : ""
          }`}
          style={{ background: theme.palette.mode === "dark" ? "#152B3A" : "" }}
          onClick={() => selectTheme("dark")}
        >
          {theme.palette.mode === "light" ? (
            <RadioButtonDefault color={"#440099"} />
          ) : (
            <RadioButtonSelected color={"#00DD88"} />
          )}
          <Box flex={1} display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
            <Typography
              variant="body1"
              style={{
                color: theme.palette.mode === "dark" ? "#00DD88" : "#0D2433",
                fontWeight: theme.palette.mode === "dark" ? "500" : "",
              }}
            >
              Dark
            </Typography>
            <DarkModeIcon color={theme.palette.mode === "dark" ? "#00DD88" : "#0D2433"} />
          </Box>
        </Box>
      </Box>
    </Menu>
  );
};
export default ThemeSettings;
